.footer {
      position: fixed;
      pointer-events: none;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: #F2F2F2;
      mix-blend-mode: multiply;
      z-index: 1;
      opacity: .8;
}

.footer-links {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      padding-right: 20px;
      padding: 12px;
}

.fa-github {
      align-items: right;
      padding: 20px;
      font-size: 30px;
      z-index: 1000;

}

.home-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
}

body .fa {
      font-size: 32px;
      opacity: 1;
      padding: 3px;
      z-index: 1000;
      cursor: pointer;
}

.body .fa-chevron-down {
      display: block;

}

.body .fa-chevron-up {
      display: none;
      font-size: 30px;
}

body .profile {
      font-size: 35px;
      color: white;
      padding: 1px;
}

body .fa-sign-out {
      font-size: 40px;
      color: grey;
      margin-right: 10px;
}