.close-button-manual {
      /* width: 40px; */
      display: flex;
      align-items: right;
      text-align: right;
      margin-left: 267px;
      justify-content: right;
      margin-bottom: 10px;
      margin-top: 4px;
      cursor: pointer;
      color: #3854A6;
      font-weight: 600;
      opacity: .8;
      font-size: 14px;
}



.manual {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-style: normal;
      /* min-height: 200px; */
      background: white;
      display: flex;
      opacity: 1;
      border-radius: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-radius: 25px;
      text-align: center;
      min-height: 580px;
      max-width: 300px;
      /*below might not be needed if not enough content */
      max-height: calc(100vh - 210px);
      /* overflow-y: auto; */
}

.manual-instruction {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-style: normal;
      /* min-height: 200px; */
      background: #F2F2F2;
      /* display: flex; */
      opacity: 1;
      border-radius: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      /* Gabe's Temporary Fix for the Manual Positioning */
      margin-top: 40%;
      margin-right: 100px;


      border-radius: 25px;
      text-align: center;
      min-height: 620px;
      max-width: 300px;
      /*below might not be needed if not enough content */
      max-height: calc(100vh - 210px);
      /* overflow-y: auto; */
}

.manual-login-message {
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 1.5px;
      /* padding-top: 10px; */
      padding-bottom: 20px;
      color: #3854A6;
}

.modal-instruction-background-left {
      pointer-events: none;

      position: absolute;
      display: flex;
      z-index: 0; 
      /* bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);*/
      
}

.modal-instruction-background {
      /* pointer-events: none; */
      position: fixed;
      bottom: 0;
       top: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 5;
}


.modal-instruction-child {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 32px;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      animation: manual-animation 800ms ease-in;
}

.modal-instruction-child-left {
      position: absolute; 
      top: 50%;
      left: 50%;
      padding: 32px;
      /* transform: translate(-50%, -50%); */
      border-radius: 8px;
      animation: manual-animation 800ms ease-in;
}

.manual-title {
      font-size: 18px;
      font-weight: 800;
      padding: 10px;
      color: #3854A6;
}

.manual-text {
     /*  width: 500px;
      background-color: grey; */
      text-align: left;
      font-size: 15px;
      padding-left: 15px;
      padding-right: 15px;

}

/* slider 





*/
#mpo-modal-controller {
      position: absolute;
      left: -999em;
      opacity: 0;
}

.mpo-modal-open:checked~.mpo-modal-wrap {
      display: block;

      
      
}

.mpo-modal-overlay {
            display: flex;
            padding: 0px;
}


/* modal */



.mpo-modal-body {
      position: relative;
      /* top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      max-height: 90%;
      padding: 15px 0; 
      background: #fff;*/
      border-radius: .25em;
      /* overflow: hidden; */
      text-align: center;
      z-index: 103;
      width: 335px;
      display: flex;
      justify-content: center;


}

.mpo-modal-content {
     /* margin: 30px 15px 50px;
       padding: 50px 0 0; */
      position: relative;
      margin: 7px 15px 50px;
      pointer-events: none;
}

.mpo-modal-slide {
      position: absolute;
      animation: manual-animation 500ms ease-in;
      width: 100%;
      min-height: 400px;
      pointer-events: none;
}




.mpo-modal-nav {
      /* position: absolute; */
      top: 50px;
      width: 100%;

}
.mpo-modal-nav label {
            background: #f7f7f7;
            color: #303030;
            cursor: pointer;
            display: inline-block;
            font: 50px Arial;
            line-height: 45px;
            padding: 0 30px 0 25px;
            text-decoration: none;
            vertical-align: middle;
            letter-spacing: -7px;
            text-align: center;
}
.mpo-modal-nav label .prev-slide {
      content: '';
      left: 0;
}

.mpo-modal-nav label.next-slide {
      content: '';
      right: 0;
} 

.mpo-modal-wrap {
      display: flex;
      justify-content: center;
}

.mpo-modal-body input[type="radio"] {
      position: relative;
            bottom: -350px;
            z-index: 1000;
            margin: 5px;
            border: none;
}

.inputs {
      position: relative;
      display: flex;
      justify-content: center;
      bottom: 10%;
      align-items: center;
      width: 300px;
      
}

.inputs input[type="radio"]{
      z-index: 1000;
      margin: 5px;
      border: none;
}

.mpo-modal-content img {
      height: 90%;
      width: 90%;
}

.tips-list-left {
      text-align: left;
}

.tips-number {
      font-size: 30px;
}
.tips-number-container ul{
    padding-left: 13px; 
      text-align: left;
      font-size: 15px;
}
.tips-number-container {
      display: flex;
}

/* @media only screen and (max-width: 1250px) {
      .manual-instruction {
            display: none;
      }
} */

.close-button-manual .actual-close-button-manual {
      margin-bottom: 0px;
       margin-top: 0px;
       position: absolute;
}

/*modal create*/
.mpo-modal-slide-create {
      position: absolute;
      animation: manual-animation 500ms ease-in;
      width: 100%;
      min-height: 400px;
      justify-content: center;
}

.mpo-modal-content-create {
      position: relative;
      display: flex;
      margin: 7px 15px 50px;
      justify-content: center;
      align-items: center;
      padding-top: 30px;
}

.mpo-modal-content-create img {
      height: 110%;
      width: 104%;
      justify-content: center;
      align-items: center;
}

.mpo-modal-wrap-create {
      display: flex;
      justify-content: center;
}

.imgur-link {
      color: #38a64a;
      font-weight: bold;
      transition: .4s;
}

.imgur-link:hover {
      color: rgb(47, 255, 54);
}

.manual-instruction {
      pointer-events: all;
}

@import "./manual-2.css"