.color-palette-left-row {
      padding: 5px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
}


.color-palette-left-row {
      margin-bottom: 15px;
}