.tile {
      border: 1px solid rgb(192, 192, 192);
      width: 30px;
      height: 30px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 5px;
}


.tile-unexplored {
      background: #F2F2F2;
      /* border-style: inset */
}

.tile-unexplored:hover {
      background: #5B6ED9;

}

.tile-unexplored:hover, .tile-flagged:hover, .tile-board-color:hover, .tile-color-selected:hover, .bomb-selection-tile:hover{
      transform: scale(1.2);
}

.tile-explored {
      background: #5B6ED9;
}


.color-tile {
      background-color: attr(data-palettecolor);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 4px;
      margin-left: 4px;
      box-shadow: 1px 1px;
      transition: border-radius .15s ease-out;
}

.tile-selected {
      background-color: #3D52B3;
      /* border-style: inset; */
}


.puzzle-tile-5x5 {
      width: 96px;
      height: 96px;
}

.puzzle-tile-10x10 {
      width: 48px;
      height: 48px;
}

.puzzle-tile-15x15 {
      width: 36px;
      height: 36px;
}

.puzzle-tile-20x20 {
      width: 24px;
      height: 24px;

}


.color-tile:hover {
      border-radius: 35%;
      /* box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24) inset; */
      transform: translateY(1px);

}






@import "./tile_overlay.css";