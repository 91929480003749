

#hiddenPixels {
      display: none
}


.puzzle-gameplay-options-container {
      /* height: 150px;
      width: 120px; */
      border-radius: 15px;
      background-color: #F2F2F2;
      margin-left: 8px;
      margin-right: 8px;
      width: 300px;
      transition: 0.4s;
}

.puzzle-gameplay-options-container:hover {
      background-color: #5B6ED9;
      color: white;
}

.puzzle-options-container {
      display: flex;
      justify-content: center;
      align-content: center;
      bottom: 0;
      width: 100%;
      margin-bottom: 50px;
}

.middle-option-container {
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
}

.image-submit-button {
      width: 100px;
      height: 35px;
      color: white;
      background-color: #5B6ED9;
      border-radius: 10px;
      text-align: center;
      margin: 15px;

}

.image-submit-button:hover {
      background-color: #3854A6;
      transition: .4s;
}

.puzzle-image-options-header {
      text-align: left;
      padding-top: 30px;
      padding-left: 30px;
}

input[type="text" i] {
      padding: 1px 2px;
      border: none;
      border-radius: 10px;
      height: 30px;
      width: 254px;

}

.puzzle-size-options-items {
      font-size: 13px;
}

.puzzle-difficulty-options-items {
      font-size: 13px;
}


input[type="radio"] {
      appearance: none;
      background-color: #fff;
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid #5B6ED9;
      border-radius: 20%;
      display: inline-grid;
      place-content: center;
}

input[type="radio"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      /* Windows High Contrast Mode */
      background-color: #5B6ED9;
}

input[type="radio"]:checked::before {
      transform: scale(1);
}

input[type="radio"]:hover {
      border-radius: 50%;
      transition: border-radius .15s ease-in;
}


.puzzle-selector-options-container {
      /* width: 150px; */
      height: 219px;
      border-radius: 15px;
      background-color: #F2F2F2;
      display: inline-flex;
      padding: 10px;
      margin-left: auto;
      justify-content: center;
      transition: 0.4s;
      text-align: center;
}

.puzzle-selector-options-container-create {
      /* width: 150px; */
      height: 219px;
      border-radius: 15px;
      background-color: #F2F2F2;
      display: inline;
      padding: 10px;
      margin-left: auto;
      justify-content: center;
      transition: 0.4s;
      text-align: center;
}

.puzzle-selector-options-container-create:hover {
      background-color: #5B6ED9;
      color: white;
}

.puzzle-selector-options-container:hover {
      background-color: #5B6ED9;
      color: white;
}

.puzzle-library-options {
      height: 219px;
      width: 150px;
      border-radius: 15px;
      background-color: #F2F2F2;
      padding: 10px;
      margin-right: auto;
      display: flex;
      transition: 0.4s;
      justify-content: center;
      align-items: center;
      flex-flow: column;
}

.puzzle-library-options:hover {
      background-color: #5B6ED9;
      color: white;
}

.save-to-library-button {
      padding: 20px;
      text-decoration: underline;
}

.share-puzzle-button {
      padding: 20px;
      text-decoration: underline;
}

.user-library-button {
      padding: 20px;
      text-decoration: underline;
}

.submit-generate-puzzle-container {
      /* border: none;
      height: 40px;
      border-radius: 14px; */
      width: 300px;
      /* height: 78px; */
      border-radius: 15px;
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
      /* background-color: rgb(217, 217, 217); */
      /* padding: 12px; */
      margin-left: 8px;
      margin-right: 8px;
      transition: 0.4s;
}

.submit-button:hover {
      background-color: #3854A6;
      color: white;
      ;

}

.submit-actual-button {
      color: white;
      display: inline-flex;

}

.generate-link {
      color: #5B6ED9;
      border-radius: 12px;
      height: 30px;
      font-size: 15px;
      transition: .4s;
      text-decoration: underline;

}

.generate-link:hover {
      color: blue;
      transform: scale(1.1)

}

.submit-button {
      color: white;
      text-align: center;
      background-color: #5B6ED9;
      border-radius: 12px;
      transition: 0.4s;

}




.puzzle-options-collapse {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 16px;
      text-align: center;
    }


.puzzle-bomb-reset {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .reset-create-puzzle {
      font-size: 20px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
    }
    
    .text-box input {
      background-color: transparent;
      border-radius: 10px;
      border-style: solid;
      border-width: 2px;
      margin: 4px;
      border-color: rgb(216, 215, 215);
      padding-left: 9px;
      font-size: 15px;
      width: 250px;
      height: 40px;
      font-family: 'Montserrat', sans-serif;
    }
    
#seed-input {
      width: 130px;
    }
    
    #seed-input-header {
      text-align: center;
      padding-left: 0px;
      padding-top: 10px;
    }


.reveal-message {
      margin-top: 30px;
    }
    
    .reveal-button {
      width: 100px;
      height: 35px;
    
      padding: 5px;
      margin: 15px;
    }
    
    .puzzle-library-options {
      height: 255px;
      justify-content: space-around;
    }
    
    .puzzle-selector-options-container, .puzzle-selector-options-container-create  {
      height: 255px;
      min-width: 160px;
    }
    
    .puzzle-gameplay-options-container-gameplay {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    .question-button {
      border: solid #5B6ED9;
      border-radius: 20%;
      border-style: solid;
      border-width: 2px;
      z-index: 2;
      /* color: white; */
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 500;
      font-size: 16px;
      background: rgb(219, 219, 219);
      /* background-color: #5B6ED9; */

    }

    .puzzle-selector-options-container-create button {
      display: inline;
    }

    .theme-drop-menu {
      display: flex;
      /* padding: 15px 20px; */
      cursor: pointer;
     
     
      }

      .theme {
            padding: 0;
            position:relative
      }

      .theme a {
            color: black; 
            font-size: 13px;
            text-decoration: none;
      }

 
.theme ul {
      margin-top: 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 200px;
      background: rgb(219, 219, 219);
      left: 0px;
      list-style: none;
      border-radius: 5px;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-10px);
      transition: all 0.4s ease;
      padding: 5px;
                  /* max-height: 110px; */
            overflow-y: hidden;
}
    
.theme li {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.theme li:hover {
      background-color: #5B6ED9;
      font-size: 30px;
      position: relative;
      z-index: 1000;
      border-radius: 3px;
}
.theme button:focus + ul {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
}

.theme-drop-menu button {
      
      border: none;
      font-size: 16px;
      /* margin: 0px; */
      cursor: pointer;
      padding-bottom: 0px;
      padding-top: 4px;
      text-decoration: underline;
      color: inherit;

      
}
     
.theme-label {
      margin: 0;
      padding-left: 19px;
      justify-content: center;
      align-items: center;
      display: flex;
      transform: translateY(110px);
}

.theme button:focus {
      transform: translateY(0px);
      transition: all 0.4s ease;
}
                  
.theme .gg-chevron-down {
      position: relative;
      display: inline-block;
      z-index: 0;
}


a {
      color: inherit ;
       text-decoration: none;
}


a:link .fa-github { 
      opacity: .3;
      
}

.library-description-text {
      font-size: 13px;
      padding: 3px;
}


.inactive-button {
      background-color: rgb(121, 131, 146);
}

.image-submit-button.inactive-button:hover {
      background-color: #3854A6;
      content: 'LOGIN'
}