.tile {
      padding: 0px;
      z-index: 10;
}

.tile-overlay {
      display: flex;
      z-index: 5;
      width: inherit;
      height: inherit;
      border: none;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: center;
}

.puzzle-tile-overlay-5x5 {
      width: 97px;
      height: 97px;
}

.puzzle-tile-overlay-10x10 {
      width: 49px;
      height: 49px;
}

.puzzle-tile-overlay-15x15 {
      width: 37px;
      height: 37px;
}

.puzzle-tile-overlay-20x20 {
      width: 25px;
      height: 25px;
}

.tile-bomb {
      background: white;
}

.tile-highlighted {
      /* background-color: #9c9c9cc3; */
}




.bomb-selection-tile.puzzle-tile-overlay-10x10,
.tile-bomb.puzzle-tile-overlay-10x10 {


      
      background: repeating-linear-gradient(45deg,
      tomato,
      tomato 2px,
      transparent 2px,
      transparent 5px);

      
      box-shadow: 0px 0px 0px 4px tomato inset;}

.bomb-selection-tile.puzzle-tile-overlay-5x5,
.tile-bomb.puzzle-tile-overlay-5x5 {

  
      
      
      background: repeating-linear-gradient(45deg,
      tomato,
      tomato 2px,
      transparent 2px,
      transparent 5px);
      /* rgb(255,141,110, .6),
      rgb(255,141,110, .6) 10px,
      rgb(255,141,110, .1) 10px,
      rgb(255,141,110, .1) 20px); */
      box-shadow: 0px 0px 0px 4px tomato inset;
}

.bomb-selection-tile.puzzle-tile-overlay-15x15,
.tile-bomb.puzzle-tile-overlay-15x15 {
      background: repeating-linear-gradient(45deg,
      tomato,
      tomato 2px,
      transparent 2px,
      transparent 5px);
      box-shadow: 0px 0px 0px 3px tomato inset;
}

.bomb-selection-tile.puzzle-tile-overlay-20x20,
.tile-bomb.puzzle-tile-overlay-20x20 {

          
      background: repeating-linear-gradient(45deg,
      tomato,
      tomato 2px,
      transparent 2px,
      transparent 5px);
      box-shadow: 0px 0px 0px 2px tomato inset;
}

body .tile-flagged {
      color: #5B6ED9;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 30px;
      z-index: 10;
      text-align: center;
      background-color: white;
}