/*library css */
.library-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 80vh;
      justify-content: center;
      align-items: center;
  }
  .library-manual {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-style: normal;
      min-height: 200px;
      background: white;
      display: flex;
      opacity: 1;
      border-radius: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-radius: 25px;
      text-align: center;
      min-height: 580px;
      min-width: 530px;
      /*below might not be needed if not enough content */
      max-height: calc(100vh - 210px);
      overflow-y: auto;
  }
  .close-button {
      z-index: 1111;
      height: 40px;
      width: 40px;
      right: 20px;
      top: 70px;
      position: absolute;
      margin: 10px;
      cursor: pointer;
  }
  
  .library-instruction-background {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 10;
  }
  .library-instruction-child {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 32px;
      transform: translate(-50%, -50%);
      border-radius: 8px;
  }
  .library-manual-title {
      font-size: 18px;
      font-weight: 800;
      padding: 10px;
      color: #3854A6;
  }

  li {
      padding-top: 9px;
  }
  
  .message {
      align-items: center;
      text-align: center;
  } 
.saved-puzzles {
      width: 500px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.made-puzzles {
      width: 500px;
      
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
}

.cover-puzzle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 140px;
      height: 140px;
      background-color: rgb(156, 156, 209);
      margin: 5px;
      border-radius: 10px;
      margin-bottom: 10px;
}

.cover-puzzle:hover {
      transform: scale(1.2)
}


.library-board-container {
      display: flex;
      align-items: center;
      justify-content: center;
      
      border-radius: 15px;
      
}

.library-board-container > tbody {
      width: 120px;
      height: 120px;
      padding: 0px;
      margin: 0px;
}
.library-board-row {
      justify-content: center;
      align-items: center;
}

.library-tile {
      
      border: none;
      padding: 0px;
}

.library-tile-5x5 {
      height: 24px;
      width: 24px;
}

.library-tile-10x10 {
      width: 12px;
      height: 12px;
}

.library-tile-15x15 {
      width: 8px;
      height: 8px;
}

.library-tile-20x20 {
      width: 6px;
      height: 6px;
}

.library-tile-unexplored {
      background: #F2F2F2;

}



.library-tile-explored {
      background: #b9c3fc;

}

.library-tile-bomb {
      background-color: rgb(252, 148, 129);
      
}

.library-tile-flagged {

}



#library-modal-child {
      height: 100%;
      
}

#library-modal {
      height: 100%;
}

#library {
      height: 100%;
      justify-content:start;
      padding-bottom: 20px;
}





.cover-puzzle:active {
      
      background: rgb(129, 129, 199);
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
     -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
          box-shadow: inset 0px 0px 5px #c1c1c1;
   outline: none;
}


#seed-title {
      width: 80%;
      font-size: 14px;
      overflow-x: auto;
}

.library-items {
      display: flex;
      width: 500px;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
}

.library-separator {
      width: 100%;
      height: 20px;
}

.library-tab {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 20px;
}
.library-tab-item {
      padding: 5px 10px;
      transition: .4s;
      border-radius: 10px;
}

.library-tab-item:hover {
      background-color: #3854A6;
      color:white;
      font-weight: 600;
      transform: scale(1.1)
}

.library-tab-selected {
      background-color: #3854A6;
      color:white;
      font-weight: 600;
      transform: scale(1.1)
}

/* Hide scrollbar for Chrome, Safari and Opera */
.library-manual::-webkit-scrollbar {
      display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .library-manual {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }