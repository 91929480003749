.session-form {
      display: flex;
      width: 100%;
      height: 80vh;
      justify-content: flex-end;
      align-items: center;
      animation: appear 350ms ease-in 1;

}

.sform {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-style: normal;
      min-height: 200px;
      background: white;
      display: flex;
      opacity: 1;
      border-radius: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-radius: 25px;
      text-align: center;
      min-height: 420px;
      min-width: 200px;

}

.login-message {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1.5px;
      color: #9c9c9c;
}



.login-button2 {
      width: 260px;
      height: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 500;
      color: grey;
      background-color: #F2F2F2;
      margin: 4px;
      cursor: pointer;
      font-family: 'Montserrat', sans-serif;
}

.login-button2:hover {
      transition: 0.5s;
      background-color: #5B6ED9;
      color: white;
}

label.login-error {
      color: #f38688;
  }