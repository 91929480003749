.number-hint-left-row,
.number-hint-top-row {
      display: flex;
      justify-content: end;
      align-items: center;

      height: 32px;
      width: 32px;
      font-size: 12px;
      font-weight: bold;
      color: rgb(73, 69, 69);
}

.number-hints-left-content {
      display: flex;
      justify-content: center;
      align-items: center;
}

.number-hint-left-row {
      flex-direction: column;
      padding-right: 2px;
      padding-left: 1px;      
      /* padding: 1px;
      padding-right: 1px; */
}

.number-hint-top-row {
      flex-direction: row;
    
}

.number-hint-top-item {
      padding: 5px;
      
}

.number-hint-left-item {
      padding: 1px;
     
}

.hint-crossout {
      text-decoration: line-through;
      color: lightgray;
}


.hint-tile-5x5 {
      width: 96px;
      height: 96px;
      font-size: 18px;
}

.hint-tile-10x10 {
      width: 48px;
      height: 48px;
      font-size: 16px;
}

.hint-tile-15x15 {
      width: 36px;
      height: 36px;
      font-size: 14px;
}

.hint-tile-20x20 {
      width: 24px;
      height: 24px;
      font-size: 12px;
}



.number-hints-left-item {
      /* color: white; */
      padding-right: 10px;
      text-align: right;
      vertical-align: middle
}

.number-hints-right-item {
      /* color: white; */
      padding-bottom: 5px;
      text-align: center;
      vertical-align: bottom;

}