.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.app {
  width: 100%;
}


html,
body {
  height: 100%;
  margin: auto;

}


.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


body {
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
  min-width: 960px;
  font-family: 'Montserrat', sans-serif;
}



@import "./css/board.css";
@import "./css/tile.css";
@import "./css/navbar.css";
@import "./css/modal.css";
@import "./css/hint.css";
@import "./css/color_palette.css";
@import "./css/puzzle_options.css";
@import "./css/footer.css";
@import "./css/progress_bar.css";
@import url('https://css.gg/chevron-down.css');